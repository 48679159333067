<template>
  <div class="default-moderar moderar-cupom">
    <div class="moderar-cupom-container">
      <h1>{{ camposInput.titulo }} <small>Área de moderação</small></h1>
      <div class="moderar-body">
        <div class="moderar-row">
          <div class="moderar-panel">
            <!-- -----------------informações do usuário -->
            <h3>Informações do usuário:</h3>

            <div class="moderar-row">
              <div class="moderar-item">
                <label>Nome:</label><br />
                <p>
                  {{
                    tabelaDetalhes.data.lista
                      ? tabelaDetalhes.data.lista.nome
                      : "--"
                  }}
                </p>
              </div>
                 <div class="moderar-item">
                <label>Data Nascimento:</label><br />
                <p v-if="tabelaDetalhes.data && tabelaDetalhes.data.lista.dataNascimento">{{ tabelaDetalhes.data.lista.dataNascimento }}</p>
                <p v-else>--</p>
              </div>
              <div class="moderar-item">
                <label>CPF:</label><br />
                <p>
                  {{
                    tabelaDetalhes.data.lista
                      ? tabelaDetalhes.data.lista.cpf
                      : "--"
                  }}
                </p>
              </div>
              <div class="moderar-item">
                <label>E-mail:</label><br />
                <p>
                  {{
                    tabelaDetalhes.data.lista
                      ? tabelaDetalhes.data.lista.email
                      : "--"
                  }}
                </p>
              </div>
            </div>
            <!-- -----------------informações do Cupom -->
            <h3>Informações do Cupom:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Data de cadastro:</label><br />
                <p>{{ tabelaDetalhes.data.lista.dataCadastro ? tabelaDetalhes.data.lista.dataCadastro : '--'}}</p>
              </div>
              <div class="moderar-item">
                <label>Número do cupom:</label><br />
                <p>{{ tabelaDetalhes.data.lista.numeroNota }}</p>
              </div>
              <div class="moderar-item">
                <label>CNPJ do estabelecimento:</label><br />
                <p>{{ tabelaDetalhes.data.lista.cnpj ? tabelaDetalhes.data.lista.cnpj : '--'}}</p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Data do cupom:</label><br />
                <p>{{ tabelaDetalhes.data.lista.dataCompra ? tabelaDetalhes.data.lista.dataCompra : '--'}}</p>
              </div>
              <div class="moderar-item">
                <label>Valor da compra:</label><br />
                <p>{{ tabelaDetalhes.data.lista.valorCupom ? tabelaDetalhes.data.lista.valorCupom : '--'}}</p>
              </div>
            </div>

            <h3>Endereço:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Logradouro:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.rua }}</p>
              </div>
              <div class="moderar-item">
                <label>Número:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.numero }}</p>
              </div>
              <div class="moderar-item">
                <label>Complemento:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.complemento ? tabelaDetalhes.data.lista.endereco.complemento : '--' }}</p>
              </div>
              <div class="moderar-item">
                <label>Bairro:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.bairro }}</p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Cidade:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.cidade }}</p>
              </div>
              <div class="moderar-item">
                <label>Estado:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.estado }}</p>
              </div>
              <div class="moderar-item">
                <label>CEP:</label><br />
                <p>{{ tabelaDetalhes.data.lista.endereco.cep }}</p>
              </div>
            </div>
            <!-- <div class="moderar-row">
              <div class="moderar-item">
                <label>UF:</label><br />
                <p>{{ tabelaDetalhes.data.uf }}</p>
              </div>
            </div> -->
            <!-- <div class="moderar-row">
              <div class="moderar-item">
                <label>Data de cadastro do cupom:</label><br />
                <p>{{ tabelaDetalhes.data.dataCadastro }}</p>
              </div>
              <div class="moderar-item">
                <label>Data da compra:</label><br />
                <p>{{ tabelaDetalhes.data.dataCompra }}</p>
              </div>
              <div class="moderar-item">
                <label>Valor total:</label><br />
                <p>{{ numberToReal(tabelaDetalhes.data.valorTotal) }}</p>
              </div>
            </div> -->
            <!-- <div class="moderar-row">
              <div class="moderar-item">
                <table v-if="produtosCupom">
                  <thead>
                    <tr>
                      <th>N° da sorte</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="moderar"
                      v-for="produtoItem in tabelaDetalhes.data.numerosSorte"
                      :key="produtoItem.id"
                    >
                      <td>{{ produtoItem.numeroSorte }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->

            <div
              class="moderar-row"
              v-if="tabelaDetalhes.data.motivoReprovacao"
            >
              <div class="moderar-item">
                <label>Motivo pelo reprovação:</label><br />
                <p>{{ tabelaDetalhes.data.motivoReprovacao }}</p>
              </div>
              <div class="moderar-item">
                <label>Data reprovação:</label><br />
                <p>{{ tabelaDetalhes.data.dataModeracao }}</p>
              </div>
              <div class="moderar-item">
                <label>Moderador:</label><br />
                <p>{{ tabelaDetalhes.data.moderador }}</p>
              </div>
            </div>

            <!-- -------------------informações dos Produtos  -->
            <!-- <h3 class="mt-4 d-inline-block w100">Produtos:</h3>
            <div class="moderar-row">
              <table v-if="produtosCupom">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Qtde</th>
                    <th>Valor </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="moderar default-formulario"
                    v-for="(produtoItem, index) in produtosCupom"
                    :key="produtoItem.id"
                  >
                    <td v-if="tabelaDetalhes.data.meioDeCadastro != 'WEB'" class="formulario-item">
                      <input type="text" name="nomeProduto" id="nomeProduto" :value= produtoItem.produto.nome>
                      <div class="custom-select">
                        <Pesquisa v-if="produtoItem.cupom.cupomStatus.id == 2" :produtosRecebidos="produtoItem" :indexRecebido="index" />
                        <Pesquisa v-else :produtosRecebidos="produtoItem.produto" :indexRecebido="index" :disabled="true" />
                      </div>
                    </td>
                    <td v-else>{{ produtoItem.produto.nome }}</td>
                    <td>{{ produtoItem.quantidadeProduto }}</td>
                    <td>{{ numberToReal(produtoItem.valorTotalProduto/produtoItem.quantidadeProduto) }}</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
            <div
              class="btns-formulario pt-4"
              :style="showModerar ? '' : 'justify-content: flex-start'"
            >
              <button
                class="btn btn-cancelar"
                :class="{ 'ml-0': !showModerar }"
                @click="cancelarFormuario"
              >
                Voltar
              </button>
              <!-- <button class="btn btn-enviar btn-reprovar" @click="reprovarCupom" v-if="showModerar">Reprovar</button> -->
              <button
                class="btn btn-enviar"
                @click="ModalBloqueia"
               :disabled="!tabelaDetalhes.data.lista.premio"
              >
                Reprovar
              </button>
              <button
                class="btn btn-aprovar"
                @click="aprovarCupom"
              :disabled="!tabelaDetalhes.data.lista.premio"
              >
                Aprovar
              </button>
            </div>
          </div>

          <div class="moderar-panel">
            <h3>Foto do Cupom:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <div class="moderar-row">
                  <!-- <div class="moderar-item">
                    <label>Nome da imagem:</label><br />
                    {{ tabelaDetalhes.data.imagem }}
                  </div> -->
                  <div class="moderar-item">
                    <!-- <label>Baixar imagem:</label> -->
                    <a class="btn btn-download-img" style=" height: auto; max-width: none; padding: 5px 15px;" :href="imgBaixar" download target="_blank" @click="downloadWithAxios(imgBaixar, 'cupom.png')">Baixar Imagem</a>
                    <!-- <a class="btn btn-download-img" @click="baixarImagem()"
                      >baixar</a 
                    >-->
                  </div>
                </div>
                <br />
                <ImagemCupom :imagem="imgBaixar" v-if="imgBaixar != null" />
                <p v-else>Sem foto</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _imagemCupom } from "@/api/_caminhosApi";
import { aprovarCupom, reprovarCupom, obterDetalhes, downloadWithAxios, listarMotivoReprovaCupom } from "@/api/cupom";
import { numberToReal } from "@/utils/money";
import { filterData, filterDataHora } from "@/utils/date";
import ImagemCupom from "@/components/imagemZoom/ImagemZoom.vue";
// import Pesquisa from "@/components/pesquisa/Pesquisa";




// import axios from "axios";

export default {
  components: {
    ImagemCupom,
    // Pesquisa
  },
  computed: {
    camposInput() {
      return this.$store.state.cupom.componenteAtivado;
    },
    formularioTipo() {
      return this.$store.state.cupom.formularioTipo;
    },
    tabela() {
      return this.$store.state.cupom.tabela;
    },
   
    idTabelaAtivada() {
      return this.$store.state.cupom.formularioEditarId;
    },
    informacoesTabelaAtivada() {
     
      return this.tabela.filter(item => item.id == this.idTabelaAtivada)[0];
    },
    tabelaDetalhes() {
      return this.$store.state.cupom.tabelaDetalhe;
    },
    statusCupom() {
      return this.$store.state.cupom.statuses;
    },
    produtosCupom() {
      return this.$store.state.cupom.produtosModerar;
    },
    showModerar() {
      return (
        this.$store.state.cupom.tabelaDetalhe.descricaoStatus == null ||
        this.informacoesTabelaAtivada.cupomStatus.descricao == "Pendente"
      );
    },
    imgBaixar() {
      return (
        this.tabelaDetalhes.data.lista.linkImgNota
      );
    },
    nomeImagem() {
      return this.tabelaDetalhes.data.lista.linkImgNota;
    }
  },
  filters: {
    filterData,
    filterDataHora
  },
  methods: {
    _imagemCupom,
    numberToReal,
    cancelarFormuario() {
      this.$store.commit("cupom/SET_CUPOM_TELA", "Lista");
      this.$store.commit("cupom/SET_PRODUTOS_MODERAR", "");
    },
    montaBody(aprovar = true) {
      let data = {};
      data.id = this.idTabelaAtivada;
      let statusName = aprovar ? "APROVADO" : "REPROVADO";
      let status = this.statusCupom.filter(obj => obj.descricao === statusName);
      data.cupomStatus = { id: status[0].id };
      this.$store.commit("cupom/SET_DATA_CUPOM", data);

      // console.log(this.$store.state.cupom.produtoPesquisado)
      // console.log(this.produtosCupom[0]
      // for (let i = 0 ; i < this.$store.state.cupom.produtoPesquisado.length ; i++){
      // let posicaoProdNovo = this.$store.state.cupom.produtoPesquisado[i].indexRecebido
      //   if(posicaoProdNovo >= 0){
      //       let posicaoProdNovo = this.$store.state.cupom.produtoPesquisado[i].indexRecebido
      //       let idNovo = this.$store.state.cupom.produtoPesquisado[i].id
      //       this.produtosCupom[posicaoProdNovo].id = idNovo
      //   }
      // }
      // console.log(this.produtosCupom)

      // data.produtosConferidos = []
      // this.produtosCupom.map(function(item){
      //   data.produtosConferidos.push(
      //     {
      //     id: item.id,
      //     quantidade: item.quantidadeProduto,
      //     valorTotal: item.valorTotalProduto
      //     }
      //   )
      // })
      // while (this.$store.state.cupom.produtoPesquisado.length) {
      //   this.$store.commit("cupom/SET_PRODUTO_PESQUISADO_LIMPO");
      // }

      return data;
    },
    reprovarCupom() {
      return reprovarCupom(this.idTabelaAtivada);
    },
    aprovarCupom() {

     return aprovarCupom(this.idTabelaAtivada);
      
    },
    obterDetalhesCupom(id) {
      obterDetalhes(id);
    },
    baixarImagem() {
      const url = this.tabelaDetalhes.data.lista.linkImgNota;
      downloadWithAxios(url, "Teste");
    },
    ModalBloqueia() {
      // this.$store.commit("user/SET_ID_USUARIO_BLOQUEADO", id);
      // this.montaBody(false);
      listarMotivoReprovaCupom()
      this.$store.commit("interacoes/SET_MODAL_BLOQUEIO", {
        ativado: true,
        tipo: "reprovarCupom"
      });
    }
  },
  mounted() {
    this.obterDetalhesCupom(this.$store.state.cupom.formularioEditarId);
  }
};
</script>

<style lang="scss" scoped>
.formulario-item {
  padding: 5px 10px !important;
  margin-bottom: 0px !important;
}
.moderar-cupom {
  .btns-formulario {
    justify-content: space-evenly;
  }
  // .btn-enviar {
  //   &.btn-reprovar {
  //     background-color: red;
  //   }
  //   &.btn-aprovar {
  //     background-color: green;
  //   }
  // }
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
