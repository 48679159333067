<template>
  <div>
    <transition name="fade-left" mode="out-in">
      <div class="cupom" v-if="formOuLista === 'Lista'" key="Lista">
        <Tabela />
      </div>
      <div v-if="formOuLista === 'Moderar'" class="cupom-formulario" key="Moderação">
        <FormularioModerar />
      </div>
    </transition>
  </div>
</template>

<script>
import Tabela from "@/components/cupom/Tabela.vue";
import * as api from "@/api/_caminhosApi";
import FormularioModerar from "@/components/cupom/FormularioModerar.vue";
export default {
  components: {
    Tabela,
    FormularioModerar
  },
  data() {
    return {
      tela: "",
      telas: [
        {
          id: 1,
          ativado: true,
          titulo: "Cupons",
          componente: "Cupom",
          apiBuscar: api._rotaListarCupomPorStatus(),
          apiEnviar: api._rotaCadastrarCupom(),
          camposObrigatorios: ["ordem", "tipo", "data", "bannerDesk"]
        }
      ]
    };
  },
  computed: {
    formOuLista() {
      return this.$store.state.cupom.telaAtivada;
    },
    componentesMenu() {
      return this.telas.filter(item => item.ativado);
    }
  },
  created() {
    this.$store.commit("cupom/SET_LINKATIVADO", this.componentesMenu[0].componente);
    this.$store.commit("cupom/SET_COMPONENTE", this.componentesMenu[0]);
    this.$store.commit("cupom/SET_CUPOM_TELA", "Lista");
  }
};
</script>

<style lang="scss" scoped>
.cupom {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.cupom-tabela {
  width: 100% !important;
}
.cupom-formulario {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 25px;
  @media (max-width: 750px) {
    padding: 25px 10px;
  }
}
</style>
