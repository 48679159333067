<template>
<div>
  <zoom-on-hover :img-normal="imagem" :scale="2"></zoom-on-hover>
</div>
</template>

<script>

export default {
  //props necessárias para o funcionamento do componente
  props: ["imagem"]
};
</script>